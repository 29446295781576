@import "~bootstrap/dist/css/bootstrap.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg-gray {
  background-color: #eee;
}

.button {
  font-size: 1rem;
  border-radius: 4px;
  background-color: #bae6fd;
  color: #0ea5e9;
  outline: none;
  border: 0;
  padding: 8px 16px;
}

.pointer {
  cursor: pointer;
}
